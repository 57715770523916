import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {


  message;

  ngOnInit() {
    this.pageService.getCms("privacy").subscribe(
      (resp: any) => {
        this.message = resp.data.content;
        console.log(this.message)
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  constructor(
    private pageService: PagesService,
  ) {

  }

}
